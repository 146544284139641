@tailwind base;
@tailwind components;
@tailwind utilities;

span,p,h1,h2,div {
  font-family: 'Inter';
}
.transition-text, .dashboard-text, .coming-soon-text {
  background: linear-gradient(to right, #9945ff, #5b9fbc, #14f195);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-text {
  letter-spacing: 0.3rem; 
}

.dashboard-small-text {
  font-size: 0.6rem; 
}

.coming-soon-text {
  letter-spacing: 0.8rem; 
  font-size: 3rem; 
  font-weight: 100;
}

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.gradient-btn {
  padding: 10px 40px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(to right, #9945ff, #5b9fbc, #14f195);
  cursor: pointer;
}

.gradient-btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background-color: #222126;
  z-index: -1;
  transition: 200ms;
}

.gradient-btn::after {
  content: attr(data);
  font-size: 16px;
  background: linear-gradient(to right, #9945ff, #5b9fbc, #14f195);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: 200ms;
}

.gradient-btn:hover {
  opacity: 80%;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.gradient-btn:hover::after {
  color: #222126;
}

.divide-logo {
  border-left: 1px solid white;
  height: 70%;
}

.social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; 
  height: 32px; 
  margin: 8px;
  border-radius: 50%; 
  transition: all 0.3s ease;
  padding: 12px; 
}

.icon {
  font-size: 16px; 
}

.twitter-btn {
  background-color: #1DA1F2;
  color: #fff;
}

.telegram-btn {
  background-color: #0088cc;
  color: #fff;
}

.docs-btn {
  background-color: #4CAF50;
  color: #fff;
}

.social-btn:hover {
  opacity: 0.8;
}

.card-select:hover {
  opacity: 0.8;
  cursor: pointer;
  
  border: double 1px transparent;
  border-radius: 12px;
  background-image: linear-gradient(#161616, #161616), 
                    linear-gradient(145deg, #05E69F, #0F0F0F 40%, #0F0F0F 75%, #0F0F0F 75%);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  box-sizing: border-box;

  width: 281.5px;
  height: 155px;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.navbar-button {
  position: absolute;
  top: 0;
  left: 0;
  border: none; 
  padding: 18px 8px; 
}

.paypaw-text {
  /* PayPaw */

  width: 77px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.25px;

  /* Main/White */
  color: #FFFFFF;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.logo-frame {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  position: absolute;
  width: 135px;
  height: 42px;
  left: 24px;
  top: 24px;
}

.navigation-text {
  /* NAVIGATION */

  position: absolute;
  width: 70px;
  height: 13px;
  left: 24px;
  top: 90px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.25px;

  /* Text/Secondary */
  color: #7A7A7A;
}

.navigation-selection {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  position: absolute;
  width: 212px;
  height: 88px;
  left: 14px;
  top: 117px;
}

.nav-btn {
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(161deg, #05E69F, #0F0F0F 41%, #0F0F0F 75%, #0F0F0F 75%);
  cursor: pointer;
}

.nav-btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background-color: #262626;
  z-index: -1;
  transition: 200ms;
}

.nav-btn::after {
  content: attr(data);
  font-size: 16px;
  background: linear-gradient(145deg, #05E69F, #0F0F0F 40%, #0F0F0F 75%, #0F0F0F 75%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: 200ms;
}

.nav-button-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.25px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.nav-button-text-selected {
  color: #FFFFFF;
}

.nav-button-text-unselected {
  color: #7A7A7A;
}

.nav-hover:hover {
  opacity: 80%;
}

.step-box {
  box-sizing: border-box;
  position: relative;
  width: 42px;
  height: 42px;

  background: radial-gradient(32.91% 32.91% at 11.02% 92.55%, rgba(189, 86, 255, 0.6) 0%, rgba(189, 86, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(40.66deg, #BD56FF 14.06%, #890DD8 31.89%, #4D007E 54.74%, #021E17 85.46%);
  border-radius: 12.25px;
  border: solid #BD56FF 2px;
}

.step-text {
  font-family: 'Inter';
  font-style: normal;
  position: absolute;
  width: 22px;
  height: 22px;
  left: 8px;
  top: 8px;

  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6.11111px;
}

.step-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 14px;

  width: 204px;
  height: 97px;
}

.step-divider {
  width: 102px;
  margin: auto;
  border: 1px solid #262626;
}

.select-frame {
  box-sizing: border-box;

  height: 438px;

  /* Main/Dark 2 */
  background: #0F0F0F;
  /* Main/Dark 5 (Stroke) */
  border: 1px solid #262626;
  border-radius: 12px;
  z-index: 9999999;
}

.search-frame {
  box-sizing: border-box;

  height: 56px;
  left: 0px;
  top: 0px;

  /* Main/Dark 5 (Stroke) */
  border-bottom: 1px solid #262626;
}

.country-size {
  width: 607px !important;
}

.gray-text {
  color: #7A7A7A;
}

.country-options {
  display: flex; 
  justify-content: 'center'; 
  align-items: center;
}

.inline-flag {
  display: inline-flex;
  width: 30px;
  height: 20px;
  overflow: hidden;
}

.inline-flag > img {
  flex: 1;
  width: 100%;
  height: 100%;
}

.confirm-btn {
  /* Nav */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  gap: 10px;

  height: 42px;

  /* Primary/Purple */
  background: #BD56FF;
  /* Primary/Purple 2 */
  border: 1px solid #9A29E3;
  border-radius: 8px;
}

.confirm-check {
  position: absolute;
  left: 16.67%;
  right: 16.67%;
  top: 25%;
  bottom: 25%;

  mix-blend-mode: normal;
  /* Main/White */
  border: 1.5px solid #FFFFFF;
}

.display-card {
  box-sizing: border-box;

  width: 281.5px;
  height: 155px;

  /* Main/Dark 3 */
  background: #161616;
  /* Main/Dark 5 (Stroke) */
  border: 1px solid #262626;
  border-radius: 12px;
}

.display-gift-card {
  box-sizing: border-box;

  width: 230px;
  height: 202px;

  /* Main/Dark 3 */
  background: #161616;
  /* Main/Dark 5 (Stroke) */
  border: 1px solid #262626;
  border-radius: 12px;
}

.gift-card-select:hover {
  opacity: 0.8;
  cursor: pointer;
  
  border: double 1px transparent;
  border-radius: 12px;
  background-image: linear-gradient(#161616, #161616), 
                    linear-gradient(145deg, #05E69F, #0F0F0F 40%, #0F0F0F 75%, #0F0F0F 75%);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  box-sizing: border-box;
}

.provider-card-currency {
  box-sizing: border-box;

  width: 264px;
  height: 36px;

  /* Main/Dark 1 */
  background: #0D0D0D;
  /* Main/Dark 5 (Stroke) */
  border: 1px solid #262626;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.back-card {
  box-sizing: border-box;

  width: 90px;
  height: 28px;


  align-items: center;
  /* Main/Dark 3 */
  background: #161616;
  /* Main/Dark 5 (Stroke) */
  border: 1px solid #262626;
  border-radius: 8px;
}

.scroll-content {
  overflow-y: auto;
  max-height: 550px;
  overflow-x: hidden;
}

.pink-btn {
  padding: 10px 40px;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  /* Primary/Purple */
  background: #BD56FF;
  /* Primary/Purple 2 */
  border: 1px solid #9A29E3;
  border-radius: 8px;
}

.pink-btn:hover {
  cursor: pointer;
  opacity: 80%;
}

.green-btn {
  padding: 10px 40px;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  /* Primary/Purple */
  background: #05e69fb7;
  /* Primary/Purple 2 */
  border: 1px #05e69fb7;
  border-radius: 8px;
}

.green-btn:hover {
  cursor: pointer;
  opacity: 80%;
}

.gray-border  {
  border: 1px solid #262626;
}

.step-img {
  position: absolute;
  width: 22px;
  height: 22px;
  left: 8px;
  top: 8px;
}

.ticker-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  gap: 6px;

  width: fit-content;
  height: 24px;

  /* Main/Dark 4 */
  background: #202020;
  mix-blend-mode: normal;
  border-radius: 4px;
}

.address-frame {
  box-sizing: border-box;

  /* Main/Dark 4 */
  background: #0D0D0D !important;
  /* Main/Dark 5 (Stroke) */
  border: 1px solid #262626 !important;
}

.copy-btn {
  background: #202020 !important;
}

.address-width {
  width: 500px;
  display: flex;
  justify-content: center;
}

.trade-width {
  width: 300px;
  display: flex;
  justify-content: center;
}

.search-width {
  display: flex;
  justify-content: center;
}

.warning-frame {
  box-sizing: border-box;

  width: 476px;
  height: 91px;
  block-size: fit-content;

  background: linear-gradient(#161616, #161616) padding-box, 
              linear-gradient(165deg, #FF9900, #262626 40%, #262626 75%, #262626 75%) border-box;
  border-radius: 6px;
  border: 1px solid transparent;
}

.warn-text {
  color: #FF9900;
}

.warning-frame > div:last-child {
  text-align: left; /* Align the text to the start */
}

.email-frame {
  box-sizing: border-box;

  height: fit-content;

  /* Main/Dark 2 */
  background: #0F0F0F;
  /* Main/Dark 5 (Stroke) */
  border: 1px solid #262626;
  border-radius: 12px;
  z-index: 9999999;
}

.history-header {
  box-sizing: border-box;

  /* Main/Dark 3 */
  background: #161616;
  /* Main/Dark 5 (Stroke) */
  border-bottom: 1px solid #262626;
}